const selectors = {
  video: '.js-media-video',
  triggerButton: '.js-media-video-trigger'
}

class mediaVideo extends HTMLElement {
  constructor () {
    super()
    this.video = this.querySelector(selectors.video)
    this.pausePlayButton = this.querySelector(selectors.triggerButton)

    if (this.video && this.pausePlayButton) {
      this.pausePlayButton.addEventListener(
        'click',
        () => {
          if(this.video.paused) {
            this.video.play()
          }else {
            this.video.pause()
          }
          this.updateButtonState()
        }
      )
      this.video.addEventListener('play', this.updateButtonState.bind(this))
      this.video.addEventListener('pause', this.updateButtonState.bind(this))

      this.updateButtonState()
    }
  }

  updateButtonState() {
    const isPaused = this.video.paused
    const state = isPaused ? 'paused' : 'playing'
    const label = isPaused ? 'Play' : 'Pause'

    this.pausePlayButton.setAttribute('aria-label', `${label} video`)
    this.pausePlayButton.setAttribute('data-video-status', state)
    this.pausePlayButton.querySelector('.is-paused').hidden =
      state !== 'playing'
    this.pausePlayButton.querySelector('.is-playing').hidden =
      state !== 'paused'
  }
}

if (!window.customElements.get('media-video')) {
  window.customElements.define('media-video', mediaVideo)
}